// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-select-product-fr-js": () => import("./../../../src/components/templates/SelectProduct.fr.js" /* webpackChunkName: "component---src-components-templates-select-product-fr-js" */),
  "component---src-components-templates-select-product-js": () => import("./../../../src/components/templates/SelectProduct.js" /* webpackChunkName: "component---src-components-templates-select-product-js" */),
  "component---src-pages-404-fr-js": () => import("./../../../src/pages/404.fr.js" /* webpackChunkName: "component---src-pages-404-fr-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

